import { type Payload } from "~/packages/core/types/Api";
import BaseModel from "~/packages/core/models/BaseModel";
import { useGlobalState } from "~/store/useGlobalState";
import GoBack from "~/packages/core/actions/utility/GoBack";

/*
* useCaptions extends defaultApi composable to use Api state and Common Api Notifications
 */

class useCaptions extends useDefaultApi {
    protected api:string = 'captions'
    protected excluded:string[] = []

    /**
     * create will create a single entity by taking the required data as model
     **/
    async create(model: BaseModel, payload?:Payload, url?:string) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].create(
                model, payload, url
            );

            if (entity.metadata?.status === "200") {
                // @ts-ignore
                useNotification().notify(
                    "success",
                    entity.metadata.response_details.messages[0],
                );
                GoBack.execute();
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }
}

export default  useCaptions;