/*
* useCampaignFilters extends defaultApi composable to use Api state and Common Api Notifications
 */

class useCampaignFilters extends useDefaultApi {
    protected override api = 'campaign_filters'
    protected override excluded: string[] = ['file_details', 'field_name'];

    async searchCampaignFilters(query: string) {
        //@ts-ignore
        return await useApi()[this.api].searchCampaignFilters(query)
    }
}


export default  useCampaignFilters;