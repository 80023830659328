import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import ImportantFilesModel from "~/packages/core/models/clientservices/ImportantFilesModel";
import ImportantFilesCollection from "~/packages/core/models/clientservices/ImportantFilesCollection";

class ImportantFilesApi extends DefaultApi {
    protected path:string = 'campaigns-important-files'
    protected collection:BaseCollection = ImportantFilesCollection
    protected model:BaseModel = ImportantFilesModel
}

export default ImportantFilesApi