import Model from "~/packages/core/models/Model";
import type { ModelOptions } from "~/packages/core/models/BaseModel";
import CategoryPivotModel from "~/packages/core/models/CategoryPivotModel";
import { get } from "lodash";

class CategoryModel extends Model {

    static override exempt_fields: string[] = [
        'active',
        'featured',
    ]

    _getters_list: string[] = [
        'is_basic',
        'content_type',
        'content_id',
    ]

    casts() {
        return {
            active: "string",
            cat_id: "uid",
            children: "array",
            name: "string",
            canonical_url: "string",
            description: "string",
            description_2: "string",
            featured: "string",
            html_page: "string",
            image_file: "string",
            meta_description: "string",
            meta_keywords: "string",
            meta_title: "string",
            parent_id: "number",
            pivot: CategoryPivotModel,
            parent: "object"
        };
    }
    /**** GETTERS ****/
    // TODO: address the issues with pivot data that needs to be managed as well
    /**
     * Getter example use case
     */

    get isBasic(): boolean {
        return get(this, ["pivot", "is_basic"])
    }

    get contentType(): string {
        return get(this, ["pivot", "content_type"])
    }

    get contentId(): number {
        return get(this, ["pivot", "content_id"])
    }

    get asset_id ():number {
        return this.cat_id
    }
    // @ts-ignore
    // get exerpt(): string {
    //   if (this.description) {
    //     return this.description.substring(0, 30) + "...";
    //   }
    // }
    /**** GETTERS END ****/
    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */
    getDefaultOptions(): ModelOptions {
        return {
            identifier: 'cat_id'
        }
    }
}

export default CategoryModel;
