import Model from "~/packages/core/models/Model";
import AssetModel from "~/packages/core/models/AssetModel";

class CaptionModel extends Model {
  override casts(): {} {
    return {
      id: "number",
      url: "string",
      asset_type: "string",
      asset_id: "number",
      order_id: "string",
      order_status: "string",
      transcript_id: "string",
      transcript_vtt: "string",
      caption_file: "any",
      api_latest_response: "string",
      speakers: "string",
      datetime_created:"string",
      datetime_updated:"string",
      asset: AssetModel
    };
  }
}

export default CaptionModel;
