import { merge } from "lodash";
import type {ModelOptions} from "~/packages/core/models/BaseModel";
import AuthorsCollection from "~/packages/core/models/AuthorsCollection";
import VendorDirectoryCollection from "~/packages/core/models/marketing/VendorDirectoryCollection";
import AssetModel from "~/packages/core/models/AssetModel";
import SlotsCollection from "~/packages/core/models/training/SlotsCollection";
import RelatedAssetsCollection from "~/packages/core/models/RelatedAssetsCollection";
import PrequalCollection from "~/packages/core/models/PrequalCollection";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";
import SlidesCollection from "~/packages/core/models/training/SlidesCollection";

class WebinarModel extends AssetModel {
  static fields: string[] = ['title', 'webinar_id']
  static search_fields: string = "title,webinar_id"
  static override exempt_fields: string[] = [
    'vendor_id',
    'required_level',
    'required_level_vp',
    'timezone',
    'lang',
    'active',
    'featured',
  ];
  casts() {
    return merge(super.casts(), {
      webinar_id: "number",
      sku: "string",
      title: "string",
      source: "string",
      background: "string",
      overview: "string",
      teaser: "string",
      highlights: "string",
      target_audience: "string",
      attending_companies: "string",
      landing_page_image: "string",
      image_file_large: "string",
      showcase_image: "string",
      home_image: "string",
      pdf: "string",
      pdf_thumb: "string",
      audio_file: "string",
      on_demand_sf_id: "string",
      on_demand_url: "string",
      on_demand_url_2: "string",
      on_demand_active: "number",
      on_home_page: "number",
      on_webinar_front_page: "number",
      on_welcome_page: "number",
      on_careers_featured: "number",
      for_credit: "number",
      keywords: "string",
      active_campaign: "number",
      gated_asset: "number",
      sponsorship_logo: "number",
      vendor_id: "number",
      required_level: "number",
      required_level_vp: "number",
      meta_title: "string",
      meta_keywords: "string",
      meta_description: "string",
      html_page_name: "string",
      downloads: "number",
      active: "string",
      featured: "string",
      num_free_attendees: "number",
      individual_price: "object",
      ondemand_price: "object",
      individual_discount_amount: "string",
      individual_discount_percent: "string",
      individual_discount_cutoff_date: "string",
      institutional_price1: "object",
      institutional_price2: "object",
      institutional_attendee_price_limit: "number",
      institutional_discount_amount: "string",
      institutional_discount_percent: "string",
      institutional_discount_cutoff_date: "string",
      date_pub: "string",
      date_posted: "string",
      vendor_teaser: "string",
      email_teaser: "string",
      email_title: "string",
      email_text_body: "string",
      popup_details: "object",
      canonical_url: "string",
      is_archived: "number",
      is_future: "number",
      is_fsa: "number",
      is_hidden_ondemand: "number",
      is_pexclusive:"number",
      timezone: "number",
      slide_previews: "number",
      on_demand_price: "string",
      on_demand_discount_percent: "string",
      on_demand_discount_cutoff_date: "string",
      on_demand_purchase_availability: "number",
      surveymonkey_id: "string",
      qmessage: "string",
      title_original: "string",
      order_regions: "string",
      cloned_hierarchy: "string",
      show_subtitles: "number",
      show_subtitles_speakers: "number",
      landing_only: "number",
      lang: "string",
      date: "string",
      asset_image: "string",
      asset_index_image: "string",
      url: "string",
      asset_description: "string",
      asset_short_description: "string",
      has_on_demand: "boolean",
      is_premium: "number",
      is_free: "number",
      is_on_demand: "boolean",
      is_on_demand_premium: "boolean",
      upcoming_date: "string",
      is_sponsored: "boolean",
      upcoming_webinars_dates: "boolean",
      duration: "string",
      has_on_demand_premium_access: "boolean",
      has_membership: "string",
      scheduled_total_cost: "string",
      on_demand_total_cost: "string",
      is_lms: "boolean",
      lms_url: "string",
      caption_id: "string",
      caption_data: "string",
      caption_speakers: "array",
      computed_individual_price: "string",
      computed_individual_discount: "string",
      computed_institutional_price1: "string",
      computed_institutional_price2: "string",
      computed_institutional_discount: "string",
      is_p_exclusive: "string",
      total_cost: "string",
      session: "string",
      file_url_no_format: "string",
      upcoming_slot_date: "string",
      premium: "object",
      slides: SlidesCollection,
      authors: AuthorsCollection,
      vendor_directory: VendorDirectoryCollection,
      slots: SlotsCollection,
      related: RelatedAssetsCollection,
      prequal: PrequalCollection,
      categories: CategoriesCollection,
    });
  }

 /** GETTERS **/
  get id(){
    return this.webinar_id
  }

  getDefaultOptions(): ModelOptions {
    return {
      identifier: 'webinar_id'
    }
  }

  /** SETTERS **/
  set id(value: number){
    this.webinar_id = value
  }


  // Static select options
  sponsoredOptions = [
    {
      optionsText: "Long",
      optionsValue: 1,
    },
    {
      optionsText: "Medium",
      optionsValue: 2,
    },
    {
      optionsText: "Short",
      optionsValue: 3,
    },
  ]

  timezoneOptions =  [
    {
      optionsText: "USA - EST",
      optionsValue: "EST",
    },
    {
      optionsText: "UK - BST (GMT +1)",
      optionsValue: "BST (GMT +1)",
    },
    {
      optionsText: "Europe - (GMT +2)",
      optionsValue: "(GMT +2)",
    },
    {
      optionsText: "India - IST",
      optionsValue: "IST",
    },
    {
      optionsText: "Singapore - SGT",
      optionsValue: "SGT",
    },
    {
      optionsText: "Australia Sydney - AEDT/AEST",
      optionsValue: "AEST",
    },
    {
      optionsText: "America Los Angeles - Pacific Time",
      optionsValue: "PST",
    },
    {
      optionsText: "America/Chicago - Central Time",
      optionsValue: "CST",
    },
    {
      optionsText: "America/Denver - Mountain Time",
      optionsValue: "MST",
    },
    {
      optionsText: "Brasilia Standard Time",
      optionsValue: "BRT",
    },
    {
      optionsText: "Japan - JST",
      optionsValue: "JST",
    },
  ]

  languageOptions = [
    {
      optionsText: "English",
      optionsValue: "en",
    },
    {
      optionsText: "Japanese",
      optionsValue: "jp",
    }
    ]
}

export default WebinarModel;
