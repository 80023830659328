import Model from "~/packages/core/models/Model";

class ImportantFilesModel extends Model {
    static override permission_name = 'campaign'
    casts(): {} {
        return {
            id: "number",
            campaign_id: "number",
            original_filename: "string",
            file_type: "string",
            file_url: "string",
            date_added: "string",
        }
    }

    get file_download_link() {
        const linkClasses = [
            "text-blue-600",             // Default text color for light mode
            "dark:text-blue-400",        // Text color for dark mode
            "hover:text-blue-800",       // Hover text color for light mode
            "dark:hover:text-blue-200",  // Hover text color for dark mode
            "decoration-2",              // Thicker underline
            "hover:decoration-4",        // Thicker underline on hover
            "dark:decoration-blue-400",  // Dark mode underline color
            "focus:outline-none",        // Remove default outline
            "focus:ring-2",              // Add focus ring
            "focus:ring-blue-500",       // Focus ring color for light mode
            "dark:focus:ring-blue-300",  // Focus ring color for dark mode
            "transition-colors",         // Smooth transition for color changes
            "duration-200"               // Speed of transitions
        ];
        return '<a target="_blank" class="' + linkClasses.join(' ') + '" href="' + this.file_url + '">Download file</a>';
    }
}

export default ImportantFilesModel