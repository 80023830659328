import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import AnnouncementsCollection from "~/packages/core/models/tools/AnnouncementsCollection";
import AnnouncementModel from "~/packages/core/models/tools/AnnouncementModel";

class AnnouncementsApi extends DefaultApi {
    protected path:string = 'announcements'
    protected collection:BaseCollection = AnnouncementsCollection
    protected model:BaseModel = AnnouncementModel
}


export default AnnouncementsApi