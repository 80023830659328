import Collection from "~/packages/core/models/Collection";
import AnnouncementModel from "~/packages/core/models/tools/AnnouncementModel";

class AnnouncementsCollection extends Collection {
    /**
     * Define associated model to the CaptionsCollection
     */
    override model(): typeof AnnouncementModel {
        return AnnouncementModel;
    }
}

export default AnnouncementsCollection;