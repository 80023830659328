import useAuth from "~/packages/core/composables/useAuth";
import type AnnouncementsCollection from "~/packages/core/models/tools/AnnouncementsCollection";
import type AnnouncementModel from "~/packages/core/models/tools/AnnouncementModel";

const useAnnouncementsAcknowledgment = (announcements: AnnouncementsCollection) => {
    const { acknowledgeAnnouncement } = useAuth();

    const acknowledgeIndividualAnnouncement = async (announcement: AnnouncementModel) => {
        await acknowledgeAnnouncement(announcement.id);
        announcements.value = announcements.value.filter(
            (item) => item.id !== announcement.id
        );
    };

    const acknowledgeAll = () => {
        if (announcements.value.length) {
            announcements.value.forEach((announcement: AnnouncementModel) => {
                acknowledgeIndividualAnnouncement(announcement);
            });
        }
    };

    return {
        acknowledgeIndividualAnnouncement,
        acknowledgeAll,
    };
}

export default useAnnouncementsAcknowledgment;
