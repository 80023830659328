import Collection from "~/packages/core/models/Collection";
import RegistrationErrorModel from "~/packages/core/models/users/RegistrationErrorModel";

class RegistrationErrorsCollection extends Collection {
    //define AdminUserModel to return model
    model(): typeof RegistrationErrorModel {
        return RegistrationErrorModel
    }
}

export default RegistrationErrorsCollection