import Collection from '~/packages/core/models/Collection';
import ImportantFilesModel from "~/packages/core/models/clientservices/ImportantFilesModel";

class ImportantFilesCollection extends Collection {
    /**
     * Define associated model to the CampaignUsersCollection
     */
    model(): typeof ImportantFilesModel {
        return ImportantFilesModel;
    }
}

export default ImportantFilesCollection;
