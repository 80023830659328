import BaseModel from "~/packages/core/models/BaseModel";
import { replace, snakeCase } from "lodash";
import AuthProcess from "~/config/Constants/AuthProcess";

class Model extends BaseModel {
  static permission_name: string = "";

  /**
   * excluded fields from default passed fields to getGridListingFields static method
   *
   * these fields will be excluded from being sent to listing api call
   * @protected
   */
  protected static excluded_grid_listing_fields:string[] = []

  /**
   * extra fields that need to be included when listing api is called, along the fields passed to getGridListingFields
   *
   * these fields will be included in listing api call
   * @protected
   */
  protected static included_grid_listing_fields:string[] = []

  override casts() {
    return {};
  }
  /**** GETTERS ****/

  /**
   * Returns an HTML anchor element with the filename as text and filepath as href
   * currently being used in the TranscodingModel and WebinarConversionModel
   * @returns {string} HTML anchor element or empty string if no files exist
   */
  get fileLink(): string {
    if (!Array.isArray(this.files) || this.files.length === 0) {
      return "";
    }

    const { file, filepath } = this.files[0];
    return `<a class="underline cursor-pointer" href="${filepath}" target="_blank" rel="noopener noreferrer">${file}</a>`;
  }

  /**** Methods****/

  // Permissions actions methods
  // In case there is a model that escapes this pattern, the methods can be overridden
  // in order to use it on models follow this example: ArticleModel.getCreateActionName()
  static getCreateActionName() {
    return this.getActionNameForCurrentModel(AuthProcess.CREATE);
  }

  static getReadActionName() {
    return this.getActionNameForCurrentModel(AuthProcess.READ);
  }

  static getUpdateActionName() {
    return this.getActionNameForCurrentModel(AuthProcess.UPDATE);
  }

  static getDeleteActionName() {
    return this.getActionNameForCurrentModel(AuthProcess.DELETE);
  }

  /**
   * By default the action names for models should be the model name without 'Model' string in it and instead of
   * camel case, it is turned into snake case (!!!also the model name should be at singular form)
   * @param action
   */
  static getActionNameForCurrentModel(action: string = AuthProcess.CREATE) {
    const permission_name = this.permission_name
      ? this.permission_name
      : snakeCase(replace(this.name, "Model", ""));
    return permission_name + "_" + action;
  }

  static getGridListingFields(fields: string[] = []): string[] {
    // If grid_listing is enabled
    if (this.excluded_grid_listing_fields.length > 0 || this.included_grid_listing_fields.length > 0) {
      // Create a Set for excluded fields for efficient lookup
      const excludedFieldsSet = new Set(this.excluded_grid_listing_fields);

      // Filter out excluded fields only if `fields` is not empty
      let adjustedFields = fields.length
          ? fields.filter((field) => !excludedFieldsSet.has(field))
          : [];

      // Include additional fields even if `fields` is empty
      const includedFieldsSet = new Set(this.included_grid_listing_fields);
      includedFieldsSet.forEach((field) => {
        if (!adjustedFields.includes(field)) {
          adjustedFields.push(field);
        }
      });

      return adjustedFields;
    }

    // If grid_listing is disabled and fields is empty, return an empty array
    return fields;
  }


}

// @ts-ignore
export default Model