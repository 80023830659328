import Model from "~/packages/core/models/Model";

class RegistrationErrorModel extends Model {
    casts(): {} {
        return {
            id: 'number',
            user_id: 'number',
            registration_error_id: 'number',
            date_added: 'string',
            value: 'string',
            is_updated: 'string',
        };
    }
}

export default RegistrationErrorModel;
