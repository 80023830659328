import Model from "~/packages/core/models/Model";
import CompanyTypeModel from "~/packages/core/models/CompanyTypeModel";
import CompanyIndustriesModel from "~/packages/core/models/CompanyIndustriesModel";

class CompanyModel extends Model {
  override casts(): {} {
    return {
        id: "number",
        account_id: "string",
        account_name: "string",
        annual_revenue: "number",
        type: "string",
        industry: "string",
        employees: "number",
        created_date: "string",
        sales_person: "string",
        billing_street: "string",
        billing_city: "string",
        billing_state_province: "string",
        billing_zip_postal_code: "string",
        billing_country: "string",
        website: "string",
        asset_under_management: "number",
        certificate_charter_number: "string",
        type_id: "number",
        industry_id: "number",
        beds_no: "string",
        sic1: "number",
        sic2: "number",
        zoom_industry_hierarchical_category: "string",
        zoom_industry: "string",
        zoom_secondary_industry_hierarchical_category: "string",
        zoom_secondary_industry:"string",
        zoom_ticker: "string",
        zoom_company_url: "string",
        zoom_fax: "string",
        zoom_phone:"string",
        zoom_parent_account_name: "string",
        is_reference: "number",
        address: "string",
        company_type: CompanyTypeModel,
        company_industry: CompanyIndustriesModel,
    }
  }

    /** GETTERS **/
    get SelectedTypeOptions () {
        return { value : this.company_type.id, title: this.company_type.type }
    }

    get SelectedIndustryOptions () {
        return { value: this.company_industry.id, title: this.company_industry.industry }
    }
   
    ExistingCountry(countries:any){
        const country = countries.toArray().find((country:any) => {
            return country.code === this.billing_country
        })
        return { value: country.code, title: country.name, states: country.states.toArray() || [] }
    }

    ExistingStateProvince(countries:any){
        const country = countries.toArray().find((country:any) => {
            if(country.code === this.billing_country){
               return country
            }
        })
        if(country.states.toArray().length > 0){
            const state = country.states.toArray().find((state:any) => {
                return state.code === this.billing_state_province
            })
            return { value: state.code, title: state.name }
        }
    }


    /** Methods **/
     generateMergeUrl(dataArray: any, selectedFields: Record<string, number>) {
        // Define all fields that need to be included
        const fields = [
            "account_name",
            "annual_revenue", 
            "type_id",
            "industry_id",
            "employees",
            "billing_street",
            "billing_city",
            "billing_state_province",
            "billing_zip_postal_code",
            "billing_country",
            "website",
            "asset_under_management",
            "certificate_charter_number",
            "beds_no",
            "sic1",
            "sic2",
            "zoom_industry_hierarchical_category",
            "zoom_industry",
            "zoom_secondary_industry_hierarchical_category",
            "zoom_secondary_industry",
            "zoom_ticker",
            "zoom_company_url",
            "zoom_fax",
            "zoom_phone",
            "zoom_parent_account_name",
        ];

        const result = {
            action: 'merge',
            [`company_id[1]`]: dataArray[0].id,
            [`company_id[2]`]: dataArray[1].id,
        };

        fields.forEach(field => {
            const value1 = dataArray[0][field];
            const value2 = dataArray[1][field];

            if (value1 !== undefined || value2 !== undefined || value1 !== null || value2 !== null) {
                result[`${field}[1]`] = value1 ?? '';
                result[`${field}[2]`] = value2 ?? '';
                
                if (field in selectedFields) {
                    result[`selected[${field}]`] = selectedFields[field] + 1;
                }
            }
        });

        // Convert result object to URL encoded string
        return Object.entries(result)
            .filter(([_, value]) => value !== undefined && value !== null)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
    }

}

export default CompanyModel