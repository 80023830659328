import { merge } from "lodash";
import AssetModel from "~/packages/core/models/AssetModel";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";
import type {ModelOptions} from "~/packages/core/models/BaseModel";

class EventModel extends AssetModel {
    static override exempt_fields: string[] = [
        'active',
    ]
    casts() {
        return merge(super.casts(), ({
            id:"number",
            title: "string",
            title2: "string",
            sub_heading: "string",
            teaser: "string",
            image_file: "string",
            image_file_large: "string",
            showcase_image: "string",
            event_date: "string",
            image_caption: "string",
            description: "string",
            active: 'string',
            associated_event_url: "string",
            categories: CategoriesCollection,
        }));
    }
    /**** GETTERS ****/

    /**
     * Getter example use case
     */
    // @ts-ignore
    get formatted_event_date(): string {
        if (this.event_date) {
            const date = new Date(this.event_date);
            return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        }
        return this.event_date;
    }
    /**** GETTERS END ****/
    getDefaultOptions(): ModelOptions {
        return {
            identifier: 'id'
        }
    }
    /**SETTERS */
}

export default EventModel;
