import { type ModelOptions } from "~/packages/core/models/BaseModel";
import { merge } from "lodash";
import AuthorsCollection from "~/packages/core/models/AuthorsCollection";
import AssetModel from "~/packages/core/models/AssetModel";
import PrequalCollection from "~/packages/core/models/PrequalCollection";
import RelatedAssetsCollection from "~/packages/core/models/RelatedAssetsCollection";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";
import Models from "~/config/Constants/Models";
import VendorDirectoryModel from "~/packages/core/models/marketing/VendorDirectoryModel";

class ArticleModel extends AssetModel {
  static fields: string[] = ['title', 'article_id']
  static search_fields: string = "title,article_id"
  static override exempt_fields: string[] = [
    'active', 
    'interviewee', 
    'vendor_id', 
    'podcast_id', 
    'in_latest_news', 
    'on_front_page', 
    'required_level_vp', 
    'required_level',
    'lang'
  ]

  casts() {
    return merge(super.casts(), {
      article_id:"uid",
      title: "string",
      description: "string",
      sub_heading: "string",
      teaser: "string",
      transcript: "string",
      email_title: "string",
      email_btn_text: "string",
      email_teaser: "string",
      meta_description: "string",
      meta_keywords: "string",
      meta_title: "string",
      active_campaign: "number",
      gated_asset: "number",
      hide_webinar_promo_link: "number",
      sponsorship_logo: "number",
      for_credit: "number",
      formated_date: "string",
      date_posted: "string",
      end_of_video_preview: "string",
      is_video: "boolean",
      content_match: "number",
      show_subtitles: "number",
      landing_only: "number",
      show_subtitles_speakers: "number",
      interviewee: "string",
      image_file: "string",
      image_file_large: "string",
      showcase_image: "string",
      active: "string",
      vendor_id: "number",
      required_level: "number",
      required_level_vp: "number",
      vendor_teaser: "string",
      authors: AuthorsCollection,
      interviewee_list: AuthorsCollection,
      prequal: PrequalCollection,
      qmessage: "string",
      on_front_page: "string",
      in_latest_news: "string",
      related: RelatedAssetsCollection,
      premium: "object",
      vendor_directory: VendorDirectoryModel,
      podcast_id: "string",
      categories: CategoriesCollection,
      image_caption:"string"
    });
  }
  /**** GETTERS ****/

  /**
   * Getter example use case
   */
  // @ts-ignore
  // get exerpt(): string {
  //   if (this.description) {
  //     return this.description.substring(0, 30) + "...";
  //   }
  // }
  /**** GETTERS END ****/
  /**
   * uncomment this method in the model, if current model has different, key name, than
   * the default ('id')
   */
  getDefaultOptions(): ModelOptions {
    return {
      identifier: "article_id",
    };
  }

  /** SETTERS */
  //Setters for Asset Details form

  set SelectedIntervieweId(interviewee_list: any) {
    let storeInterviewID = [] as any[];
    interviewee_list.map((interviewee: any) => {
      storeInterviewID.push(interviewee.author_id);
    });
    this.interviewee = storeInterviewID.toString();
  }

  //setters for email info form
  //email title
  set EmailTitle(email_title: string) {
    this.email_title = email_title === "" ? this.title : email_title;
  }

  //email content
  set EmailContent(email_content: string) {
    this.email_teaser = email_content === "" ? this.teaser : email_content;
  }

  //email teaser
  set EmailTeaser(email_teaser: string) {
    this.vendor_teaser = email_teaser === "" ? this.teaser : email_teaser;
  }

  //setters for Seo Data form
  //Meta title
  set MetaTitle(meta_title: string) {
    this.meta_title = meta_title === "" ? this.title : meta_title;
  }
  //Meta description
  set MetaDescription(meta_description: string) {
    this.getModelName() === Models.Interview
      ? (this.meta_description =
          meta_description === "" ? this.description : meta_description)
      : (this.meta_description =
          meta_description === "" ? this.teaser : meta_description);
  }

  /** SETTERS END */
}

export default ArticleModel;
