import Model from "~/packages/core/models/Model";
import {trim} from "lodash";

class ImportModel extends Model {
    static permission_name: string = "import_gate";
    override _getters_list: string[] = [
        'preview_selected_fields',
        'uploaded_file'
    ]

    override casts() {
        return {
            id: "uid",
            preferences_selector: "number",
            upload_sp: "boolean",
            is_acq: "boolean",
            imp_new_comp: "boolean",
            is_update: "boolean",
            data_append_project: "boolean",
            deactivate_contacts_after: "boolean",
            is_event_subscribers: "boolean",
            is_cio_subscribers: "boolean",
            is_cyed_subscribers: "boolean",
            activate_contacts: "boolean",
            deactivate_contacts_reason: "string",
            csvfile: "any",
            fields: "array",
            filename: "string",
            teaser: "string",
            emails_update: "boolean",
            deactivate_contacts: "boolean"
        };
    }
    /**** GETTERS ****/
    get preview_selected_fields() {
        let fields = '';
        this.fields.forEach((field:string, k:number) => {
            fields += field + '; '
        })
        return trim(fields, '; ')
    }

    get uploaded_file() {
        // @ts-ignore
        return this.csvfile instanceof File ? this.csvfile.name : '';
    }
    /** Getters END */

    /** Methods */
    public email_update_allowed_fields = ['email', 'new_email']  
    public deactivate_contacts_allowed_fields = ['email']  
    public email_update_enabled_options = ['upload_sp', 'is_cyed_subscribers']
    public deactivate_contacts_enabled_options = [ 'is_cyed_subscribers']

    getRequiredFields(isEmailUpdate?: boolean, isDeactivateContacts?: boolean): string[] {
        if (isEmailUpdate) {
            return this.email_update_allowed_fields
        }
        
        if (isDeactivateContacts) {
            return this.deactivate_contacts_allowed_fields
        }
        
        return []
    }

    shouldDisableField(field: string, isEmailUpdate?: boolean, isDeactivateContacts?: boolean): boolean {
        if (isEmailUpdate && this.email_update_enabled_options.includes(field)) {
            return false
        }
        
        if (isDeactivateContacts && this.deactivate_contacts_enabled_options.includes(field)) {
            return false
        }

        if (isEmailUpdate) {
            return !this.email_update_allowed_fields.includes(field)
        }
        if (isDeactivateContacts) {
            return !this.deactivate_contacts_allowed_fields.includes(field)
        }
        return false
    }
    /** Methods END */
}

export default ImportModel;
