import Model from "~/packages/core/models/Model";
import BaseContentModel from "~/packages/core/models/emailcenter/BaseContentModel";

class EmailTemplateModel extends Model {
    static permission_name:string = "emails_template"
    static override exempt_fields:string[] = [
        'website'
    ]
  casts(): {} {
    return {
        id:"number",
        website:"string",
        date:"string",
        type:"string",
        is_w3c_valid:"string",
        content: BaseContentModel,
        type_data:"object"
    }
  }

  /** Methods End **/

    referralOptions = [
        {
            optionsText: "SUB",
            optionsValue: "SUB",
        },
        {
            optionsText: "ACQ",
            optionsValue: "ACQ",
        },
    ]

    directLinkOptions = [
        {
            optionsText: "Yes",
            optionsValue: "1",
        },
        {
            optionsText: "No",
            optionsValue: "0",
        },
    ];

    AssetOptions = [
        {
            optionsText: "Article",
            optionsValue: "AR",
        },
        {
            optionsText: "Agency Alert",
            optionsValue: "AA",
        },
        {
            optionsText: "Blog",
            optionsValue: "BL",
        },
        {
            optionsText: "Interview",
            optionsValue: "PD",
        },
        {
            optionsText: "Press Release",
            optionsValue: "PR",
        },
        {
            optionsText: "White Paper",
            optionsValue: "WP",
        },
        {
            optionsText: "Webinar",
            optionsValue: "WB",
        },
        {
            optionsText: "Handbook",
            optionsValue: "HB",
        },
    ];

    FooterIdOptions = [
        {
            optionsText: "BIS Enews - Catalog",
            optionsValue: "10",
        },
        {
            optionsText: "BIS Enews - Catalog 2",
            optionsValue: "17",
        },
        {
            optionsText: "BIS Webinars",
            optionsValue: "14",
        },
        {
            optionsText: "Blank Footer",
            optionsValue: "23",
        },
        {
            optionsText: "Careers New Test",
            optionsValue: "30",
        },
        {
            optionsText: "CIS Enews",
            optionsValue: "26",
        },
        {
            optionsText: "CU Enews - Catalog",
            optionsValue: "9",
        },
        {
            optionsText: "CU Enews - Catalog 2",
            optionsValue: "18",
        },
        {
            optionsText: "CUIS Webinars",
            optionsValue: "15",
        },
        {
            optionsText: "DBT Enews Catalog 2",
            optionsValue: "28",
        },
        {
            optionsText: "DBT Membership",
            optionsValue: "27",
        },
        {
            optionsText: "GIS Enews -  Catalog 2",
            optionsValue: "20",
        },
        {
            optionsText: "GIS Enews - Catalog",
            optionsValue: "5",
        },
        {
            optionsText: "GIS Enews - Membership",
            optionsValue: "6",
        },
        {
            optionsText: "HIS Enews - Catalog",
            optionsValue: "11",
        },
        {
            optionsText: "HIS Enews - Catalog 2",
            optionsValue: "19",
        },
        {
            optionsText: "HIS Enews - Membersips",
            optionsValue: "12",
        },
        {
            optionsText: "HIS Webinars",
            optionsValue: "16",
        },
        {
            optionsText: "IRT Enews Catalog 2",
            optionsValue: "29",
        },
    ];

    //select options for email_templates form template type
    templateTypes = [
        { name: "Enews", id: "14", value: "14" },
        { name: "Article", id: "19", value: "19" },
        { name: "Whitepaper", id: "7", value: "7"},
        { name: "Webinars", id: "8", value: "8" },
        { name: "Interviews/Podcasts", id: "9", value: "9"},
        { name: "Vendor Assets", id: "18", value: "18" },
    ]

    // load template based on template type selected
    static componentMapping = {
        "19" : "custom-newsletters-articles-data",
        "7" : "custom-newsletters-whitepapers-data",
        "8" : "custom-newsletters-webinars-data",
        "9" : "custom-newsletters-interviews-data",
        "18" : "custom-newsletters-vendorassets-data",
        "14" : "custom-newsletters-enews-data",
    }

    webinar_timezone_options = [
        {
            optionsText: "USA - EST",
            optionsValue: "EST",
        },
        {
            optionsText: "UK - BST (GMT +1)",
            optionsValue: "BST (GMT +1)",
        },
        {
            optionsText: "Europe - (GMT +2)",
            optionsValue: "(GMT +2)",
        },
        {
            optionsText: "India - IST",
            optionsValue: "IST",
        },
        {
            optionsText: "Singapore - SGT",
            optionsValue: "SGT",
        },
        {
            optionsText: "Australia/Sydney - AEDT/AEST",
            optionsValue: "AEST",
        },
    ]

    featuredItemOptions = [
        {
            optionsText: "Article",
            optionsValue: "AR",
        },
        {
            optionsText: "Agency Alert",
            optionsValue: "AA",
        },
        {
            optionsText: "Blog",
            optionsValue: "BL",
        },
        {
            optionsText: "Interview",
            optionsValue: "PD",
        },
        {
            optionsText: "Press Release",
            optionsValue: "PR",
        },
    ]

    sidebarOptions = [
        {
            optionsText: "Article",
            optionsValue: "AR",
        },
        {
            optionsText: "Blog",
            optionsValue: "BL",
        },
        {
            optionsText: "Interview",
            optionsValue: "PD",
        },
        {
            optionsText: "Press Release",
            optionsValue: "PR",
        },
    ];


    //Articles specific options

    /** SETTERS **/
    set type_data_select(typeData: any) {
        this.type_data = {value:typeData.id, name:typeData.name};
    }
}

export default EmailTemplateModel