import Model from "~/packages/core/models/Model";

class AnnouncementModel extends Model {
    override casts(): {} {
        return {
            id: "number",
            title: "string",
            message: "string",
            is_expired: "boolean",
            expires_at: "string"
        };
    }
}

export default AnnouncementModel;
