import Collection from "~/packages/core/models/Collection";
import CountryWithStatesModel from "~/packages/core/models/CountryWithStatesModel";

class CountriesWithStatesCollection extends Collection {
    //define country_with_states model to return model
    model(): typeof CountryWithStatesModel {
        return CountryWithStatesModel
    }

    /**** GETTERS ****/
    //modifying the object that we get from response to show country_with_states list in Multiselect
    get SelectOptions(): {} {
        return this.map((country_with_state: CountryWithStatesModel) => {
            // @ts-ignore
            return { value: country_with_state.code, title: country_with_state.name, states: country_with_state.states.toArray() || [] }
        });
    }

    /** METHODS **/
    /*
    * This Method will take selected country from select field and returns all the states
    * Associated with that country and populates them into state select field
    * For Usage Check --> userRegistration -> companies -> AddCompanyForm
     */

    states = []
     getSelectedCountryStates (selected_country:any) {
        this.states = []
        const selectedCountry = this.filter((country:any) =>{
            return country.code === selected_country.value
        })

        //@ts-ignore
        if(selectedCountry[0].states.length > 0){
            //@ts-ignore
            this.states = selectedCountry[0].states.map((state:any) => {
                return { value: state.code, title: state.name, country_code: state.country_code }
            })
        }
        return this.states
    }
}

export default CountriesWithStatesCollection