import { merge } from "lodash";
import {type ModelOptions } from "~/packages/core/models/BaseModel";
import AssetModel from "~/packages/core/models/AssetModel";
import AuthorsCollection from "~/packages/core/models/AuthorsCollection";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";
import RelatedAssetsCollection from "~/packages/core/models/RelatedAssetsCollection";
import BlogsModel from "~/packages/core/models/content/BlogsModel";
import VendorDirectoryModel from "~/packages/core/models/marketing/VendorDirectoryModel";

class BlogPostModel extends AssetModel {
  static fields: string[] = ["title", "post_id"];
  static permission_name: string = "blog";
  static override exempt_fields: string[] = [
    'blog_id',
    'vendor_id',
    'website',
    'in_latest_news',
    'on_front_page',
    'required_level_vp',
    'required_level'
  ]
  casts() {
    return merge(super.casts(), {
      post_id: "number",
      blog_id: "number",
      blogger_id: "number",
      vendor_id: "number",
      authors: AuthorsCollection,
      title: "string",
      sub_heading: "string",
      teaser: "string",
      content: "string",
      pull_quote: "string",
      active_campaign: "number",
      gated_asset: "number",
      sponsorship_logo: "number",
      required_level: "number",
      required_level_vp: "number",
      meta_title: "string",
      meta_keywords: "string",
      meta_description: "string",
      stamp: "string",
      website: "string",
      on_front_page: "string",
      sponsored: "number",
      email_teaser: "string",
      email_title: "string",
      popup_details: "object",
      image: "string",
      image_large: "string",
      showcase_image: "string",
      image_caption: "string",
      in_latest_news: "string",
      canonical_url: "string",
      vendor_directory: VendorDirectoryModel,
      premium: "object",
      categories: CategoriesCollection,
      related: RelatedAssetsCollection,
      blog: BlogsModel,
    });
  }
  /**** GETTERS ****/

  /**
   * Getter example use case
   */
  // @ts-ignore
  // get exerpt(): string {
  //   if (this.description) {
  //     return this.description.substring(0, 30) + ...;
  //   }
  // }
  /**** GETTERS END ****/
  /**
   * uncomment this method in the model, if current model has different, key name, than
   * the default ('id')
   */
  get id() {
    return this.post_id;
  }

  set id(value: number) {
    this.post_id = value;
  }

  getDefaultOptions(): ModelOptions {
    return {
      identifier: "post_id",
    };
  }

  /**SETTERS */

  //email content
  // set InterviewEmailContent(email_content: string) {
  //     this.email_content = email_content ===  ? this.description : email_content;
  // }
}

export default BlogPostModel;
