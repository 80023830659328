import Model from "~/packages/core/models/Model";
import  {type ModelOptions} from "~/packages/core/models/BaseModel";
import CountedAssetViewModel from "~/packages/core/models/content/relation_models/CountedAssetViewModel";
import { isEmpty } from "lodash";

class AssetModel extends Model {
    casts() {
        return {
            asset_id: "number",
            asset_type: "string",
            asset_media_url: "string",
            image_small: "string",
            image_medium: "string",
            image_large: "string",
            counted_asset_views: CountedAssetViewModel,
        };
    }
    /**** GETTERS ****/
    // @ts-ignore
    get author_names(): string {
        if (!this.authors.length) return '';

        return this.authors.map(author => author.name).join(', ');
    }

    get views_number(): number {
        if (!this.counted_asset_views) return 0;

        return this.counted_asset_views.views;
    }

    get asset_vendor_active(): boolean {
        return this.vendor?.asset_id ? true : false
    }

    set asset_vendor_active(value) {
        // @ts-ignore
        if(value === true) {
            // @ts-ignore
            this.vendor.asset_id = this.asset_id;
            // @ts-ignore
            this.vendor.asset_type = this.asset_type;
        } else {
            // @ts-ignore
            this.vendor = {}
        }

        (new useAssets()).handleRelation('vendor', this, {}, '/whitepaper')
    }
    
    get construct_asset_route(): string {
        let assetType = this.asset_type;
        
        switch (assetType) {
            case 'podcast':
                assetType = 'interview';
                break;
            case 'blogpost':
                assetType = 'blogs';
                break;
        }
        
        return `/content/${assetType}/${this.asset_id}`;
    }

    get isPremiumExclusiveAsset(): boolean {
        return !isEmpty(this.premium);
    }

    set isPremiumExclusiveAsset(value: boolean | number) {
        const isTrue = Boolean(value);
        
        if (isTrue) {
            this.premium = {
                content_type: this.asset_type,
                content_id: this.asset_id,
                is_premium: true
            };
        } else {
            this.premium = []
        }
    }

    /**** GETTERS END ****/
    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */
    getDefaultOptions(): ModelOptions {
        return {
            identifier: 'asset_id'
        }
    }

    /**
     * override for assets that have a different column to store the pdf file generated thumb image
     */
    getPdfThumbColName(): string {
        return 'logo' // this is default as it is the thumb column name used for whitepapers and for handbooks
    }

    // Setter for logo to set a new value and also update image properties
    setThumb(thumb: string, thumb_col_name: string) {
        if (this[thumb_col_name] !== thumb) {
            this[thumb_col_name] = thumb;

            // When logo changes, update image_* properties as well
            this.image_small = thumb;
            this.image_medium = thumb;
            this.image_large = thumb;
        }
    }
    
    //Construct preview URL's
    handle_asset_preview_url(preview_type: string, site: string, identifier: any): string {
        let assetType = this.asset_type;
        
        switch (assetType) {
            case 'podcast':
                assetType = 'interview';
                break;
            case 'blogpost':
                assetType = 'blog';
                break;
        }
        
        const baseUrl = `https://www.${site}`;
        const assetPreview = `${assetType}s.php?${identifier}=${this.asset_id}&preview=inactive_${this.asset_type === 'blogpost' ? 'blog': this.asset_type}`;
        const homePreview = `index.php?id=${this.asset_id}&preview=inactive_${this.asset_type === 'blogpost' ? 'blog': this.asset_type}`;
        
        switch (preview_type) {
            case 'preview':
                return `${baseUrl}/${assetPreview}`;
            case 'home-preview':
                return `${baseUrl}/${homePreview}`;
            default:
                return '';
        }
    }

    // handle asset categories
    /**
     * Checks if any of the model's state properties has a value for active or website etc..
     * @param model - The asset model instance
     * @returns boolean indicating if any state property has a value
     */
    hasCategoriesState(model: AssetModel | undefined, stateProperties: string[]): boolean {
        return stateProperties.some(prop => Boolean(model?.[prop as keyof AssetModel]));
    };

    /**
     * Gets the first non-null state property value for active or website etc..
     * @param model - The asset model instance
     * @returns The first non-null state value or undefined
     */
    getCategoriesState (model: AssetModel | undefined, stateProperties: string[]){
        return stateProperties.find(prop => Boolean(model?.[prop as keyof AssetModel]));
    };
    
    /**
     * Handles the addition of categories to the asset model.
     * @param categories - An array of category objects.
     * @param isBasic - A number indicating whether the category is basic.
     */
    handleAssetCategories(categories: any[], isBasic: number){
        categories.forEach(cat => {
                this?.categories?.add({
                    cat_id: cat,
                    content_type: this.asset_type,
                    content_id: this.asset_id,
                    is_basic: isBasic
                });
        });
    }

    /**
     * Separates categories into primary and secondary based on their pivot.is_basic value
     * @returns Object containing arrays of primary and secondary category IDs
     */
    getSelectedCategories() {
    const selected = { primary: [], secondary: [] };

    if (this.asset_id && this.categories.length > 0) {
      this.categories.forEach((cat: CategoryWithPivot) => {
            if (cat.pivot.is_basic === 1) {
                selected.primary.push(cat.cat_id);
            } else if (cat.pivot.is_basic === 0) {
                selected.secondary.push(cat.cat_id);
            }
            });
        }
        return selected;
    }
    
    
    //Assets select Options
    sponsoredOptions = [
        {
            optionsText: "Long",
            optionsValue: 1,
        },
        {
            optionsText: "Medium",
            optionsValue: 2,
        },
        {
            optionsText: "Short",
            optionsValue: 3,
        },
    ]

    languageOptions = [
        {
            optionsText: "English",
            optionsValue: "en",
        },
        {
            optionsText: "Japanese",
            optionsValue: "jp",
        },
    ];

    //TODO - re-visit after content-hubs & survey integration
    static assetOptions = [
        {
            optionsText: "Article",
            optionsValue: "article",
        },
        {
            optionsText: "Blog Post",
            optionsValue: "blogpost",
        },
        {
            optionsText: "Podcast(Interview)",
            optionsValue: "podcast",
        },
        {
            optionsText: "Handbook",
            optionsValue: "handbook",
        },
        {
            optionsText: "Webinar",
            optionsValue: "webinar",
        },
        {
            optionsText: "Whitepaper",
            optionsValue: "whitepaper",
        },
    ];

}

export default AssetModel;
